<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Products</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="productTypeSelect"
        :items="productTypes"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Product Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="green" dark to="/products/detail" class="mb-2"
        >Create</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.c="{ item }">
        <div>
          {{ item.c | formatMoney }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import router from "@/router";
import productType from "@/lib/productType";

export default {
  data() {
    return {
      all: [],
      productTypes: productType,
      productTypeSelect: { key: -1, value: "All" },
      search: "",
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "i",
        },
        { text: "Value", value: "c" },
        { text: "Type", value: "t" },
        { text: "Price", value: "p" },
        { text: "ShopCard", value: "s" },
        { text: "Segment", value: "g" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("products/delete", item.i);
      await this.initialize();
    },
    editItem(item) {
      router.push("/products/detail/" + item.i);
    },
    async initialize() {
      await this.$store.dispatch("products/getAll", {
        search: this.search,
        type: this.productTypeSelect.key,
      });
      this.all = this.$store.state.products.all;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
